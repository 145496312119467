import { jwtDecode } from "jwt-decode";
import React from "react";
import { ApiService } from "../services";
interface AuthContextType {
  getToken: () => string | null;
  setToken: (
    token: string,
    refreshToken: string,
    accountId: string,
    name: string
  ) => void;
  logOut: () => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
const TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";
const ACCOUNT_ID_KEY = "accountId";
const NAME_KEY = "name";
const ROLES_KEY = "roles";

function AuthProvider({ children }: { children: React.ReactNode }) {
  const getToken = () => {
    const token = window.localStorage.getItem(TOKEN_KEY);
    return token;
  };

  const setToken = (
    accessToken: string,
    refreshToken: string,
    accountId: string,
    name: string
  ) => {
    window.localStorage.setItem(TOKEN_KEY, accessToken);
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    window.localStorage.setItem(ACCOUNT_ID_KEY, accountId);
    window.localStorage.setItem(NAME_KEY, name);

    const aToken = jwtDecode<any>(accessToken);
    if (aToken.account.roles)
      window.localStorage.setItem(
        ROLES_KEY,
        JSON.stringify(aToken.account.roles)
      );
  };

  const logOut = () => {
    const api = new ApiService();
    api.post("/authorization/logout", {}).then(() => {
      window.localStorage.removeItem(TOKEN_KEY);
      window.localStorage.removeItem(REFRESH_TOKEN_KEY);
      window.localStorage.removeItem(ACCOUNT_ID_KEY);
      window.localStorage.removeItem(NAME_KEY);
      window.localStorage.removeItem(ROLES_KEY);
    });
  };

  let value = { getToken, setToken, logOut };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
